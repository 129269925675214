import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import langEn from "../data/lang/en.json";

const resources = {
    en: langEn,
    fr: {translation: {}},
};

// Add FR to the resources from the HTML
Array.from(document.querySelectorAll("[data-i18n]")).forEach(element => {
    console.log();
    resources.fr.translation[element.dataset.i18n] = element.textContent;
});

i18n.use(detector).init({
    resources,
});

/**
 * Translate the page using data-i18n attribute
 * @param {*} useNavigatorLang - if true, use the language of the navigator
 */
const translate = lng => {
    // translate using data-i18n attribute
    Array.from(document.querySelectorAll("[data-i18n]")).forEach(element => {
        element.textContent = i18n.t(element.dataset.i18n);
        element.dataset.text = i18n.t(element.dataset.i18n);
    });
};

// call translate function when language is changed
i18n.on("languageChanged", lng => {
    translate(lng);
});

// check the language of the navigator
if (!/^fr\b/.test(navigator.language)) {
    i18n.changeLanguage("en");
}

// add event on lang buttons
Array.from(document.querySelectorAll("[data-lang]")).forEach(element => {
    element.addEventListener("click", () => {
        if (element.dataset.lang !== i18n.language) {
            i18n.changeLanguage(element.dataset.lang);
        }

        // scroll to the skills section
        const skillsSection = document.querySelector("#skills");
        skillsSection.scrollIntoView({behavior: "smooth"});
    });
});

translate();
