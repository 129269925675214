class Pacman {
    constructor(x, y, radius, speed, mouthSpeed, color) {
        this.x = x;
        this.y = y;
        this.radius = radius;
        this.mouthSpeed = mouthSpeed;
        this.direction = 1;
        this.speed = speed;
        this.color = color;
        this.mouth = {
            start: 0.2,
            end: 1.8,
        };
    }

    drawPacman(ctx) {
        ctx.beginPath();
        ctx.arc(
            this.x,
            this.y,
            this.radius,
            this.mouth.start * Math.PI,
            this.mouth.end * Math.PI,
        );
        ctx.lineTo(this.x, this.y);
        ctx.fillStyle = this.color;
        ctx.fill();
        ctx.closePath();
    }

    updatePacman(size) {
        // Update position
        if (this.x > size.width + this.radius * 2) {
            this.direction = -1;
            // reset mouth
            this.mouth.start = -0.8;
            this.mouth.end = 0.8;
        } else if (this.x < 0 - this.radius * 2) {
            this.direction = 1;
            this.mouth.start = 0.2;
            this.mouth.end = 1.8;
        }

        this.x += this.speed * this.direction;

        /// Update mouth
        if (this.direction === 1) {
            /// Right
            if (this.mouth.end < 1.8) {
                this.mouthSpeed = -this.mouthSpeed;
            } else if (this.mouth.end > 2) {
                this.mouthSpeed = -this.mouthSpeed;
            }
        } else {
            /// Left;
            if (this.mouth.end < 0.8) {
                this.mouthSpeed = -this.mouthSpeed;
            } else if (this.mouth.end > 1) {
                this.mouthSpeed = -this.mouthSpeed;
            }
        }

        this.mouth.start -= this.mouthSpeed;
        this.mouth.end += this.mouthSpeed;
    }
}

// Gums
const drawGums = (ctx, size, pacman) => {
    if (pacman.direction === 1) {
        for (let i = 0; i < 15; i++) {
            if (pacman.x < (size.width / 10) * i) {
                ctx.beginPath();
                ctx.arc(
                    (size.width / 10) * i,
                    size.height / 2,
                    size.height / 100,
                    0,
                    2 * Math.PI,
                );
                ctx.fillStyle = "white";
                ctx.fill();
                ctx.closePath();
            }
        }
    }
    if (pacman.direction === -1) {
        for (let i = 0; i < 15; i++) {
            if (pacman.x > (size.width / 10) * i) {
                ctx.beginPath();
                ctx.arc(
                    (size.width / 10) * i,
                    size.height / 2,
                    size.height / 100,
                    0,
                    2 * Math.PI,
                );
                ctx.fillStyle = "white";
                ctx.fill();
                ctx.closePath();
            }
        }
    }
};

const pacmanBanner = (canvas, pacmanColor) => {
    const size = {
        width: canvas.clientWidth,
        height: canvas.clientHeight,
    };

    canvas.width = canvas.height * (size.width / size.height);

    window.addEventListener("resize", () => {
        size.width = canvas.clientWidth;
        size.height = canvas.clientHeight;
        canvas.width = canvas.height * (size.width / size.height);
    });

    const ctx = canvas.getContext("2d");
    ctx.canvas.width = size.width;
    ctx.canvas.height = size.height;

    const pacman = new Pacman(
        0,
        size.height / 2,
        size.height / 4,
        1,
        0.03,
        pacmanColor,
    );

    const clearCanvas = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };

    const draw = () => {
        clearCanvas();
        pacman.updatePacman(size);
        pacman.drawPacman(ctx);
        drawGums(ctx, size, pacman);
        requestAnimationFrame(draw);
    };

    draw();
};

export default pacmanBanner;
